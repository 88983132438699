<template>
  <SmartLink
    :native="false"
    to="/account/user-onboarding"
    class="get-started-cta"
  >
    <TopNavigationElement
      :text="$t('dictionary.getStarted')"
      :icon-src="$options.locals.heartIcon"
      :additional-attrs="additionalAttrs"
      :hide-text-on-tablet="true"
      :is-get-started="true"
      @click="preventDefault()"
    />
  </SmartLink>
</template>

<script>
import TopNavigationElement from '@sephora-asia/core-top-navigation-element/TopNavigationElement.vue'
import HeartIcon from '~/assets/images/heart-red.svg'

export default {
  name: 'TopNavigationGetStarted',

  components: {
    TopNavigationElement
  },

  locals: {
    heartIcon: HeartIcon
  },

  computed: {
    additionalAttrs() {
      return {
        'data-analytics-id': 'header-click',
        'data-analytics-label': 'get-started'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.get-started-cta {
  margin-left: 20px;

  @include until-widescreen {
    margin-left: 15px;
  }
}
</style>

<template>
  <div>
    <SidebarNavItem
      :text="$t('dictionary.getStarted')"
      :level="1"
      url="/account/user-onboarding"
      :is-get-started="true"
      :icon-src="$options.locals.heartIcon"
      :has-separator="true"
      data-analytics-id="nav-link"
      :data-analytics-label="$t('dictionary.getStarted')"
    />
  </div>
</template>

<script>
import SidebarNavItem from '@sephora-asia/core-sidebar-nav-item/SidebarNavItem.vue'
import HeartIcon from '~/assets/images/heart-red.svg'

export default {
  name: 'SidebarNavMenuGetStarted',

  components: {
    SidebarNavItem
  },

  locals: {
    heartIcon: HeartIcon
  }
}
</script>
